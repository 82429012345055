@font-face {
  font-family: "DINRoundPro-Light";
  src: url("../../fonts/DINRoundPro-Light.woff") format("woff");
}

@font-face {
  font-family: "Handel-Gothic-BT";
  src: url("../../fonts/HANDGOTN.TTF") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

.nav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 65px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.nav-header {
  background-color: rgba(22, 22, 23, 0.8);
  backdrop-filter: blur(10px);
  color: #f7f7f7;
  padding: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.navbar-brand {
  display: flex;
  align-items: center;
}

.logo-image {
  margin-right: 10px;
  width: 52px;
  height: auto;
}
.nav-links {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 50;
  color: #f7f7f7;
  display: flex;
  margin-right: 10%;
}
.link {
  margin-left: 30px;
}
.link-btn {
  background-color: transparent;
  color: #dbdbdb;
  font-size: 14px;
  transition: color 0.3s ease;
  border: none;
}
.active-link {
  color: white;
}

.link-btn:hover {
  color: white;
  cursor: pointer;
}
.application-number {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 50;
  color: #f7f7f7;
  margin-left: 10%;
  padding-left: 5%;
  display: inline;
  width: 100%;
  white-space: nowrap;
  /* width: 10%; */
}
.dealer-connect-logo-image {
  width: 40%;
}

.gls-logo-name {
  font-family: "Handel-Gothic-BT";
  font-weight: 30;
  display: inline;
  width: 100%;
  white-space: nowrap;
  margin-left: 2%;
}
