.footer {
    background-color: #434F59;
    text-align: center;
    padding: 20px;
    left: 0;
    bottom: 0;
    width: 100%;
}
.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-image-white {
    margin-right: 10px;
    width: 175px;
    height: auto;
}
.footer-text {
    color: white;
    font-size: 12px;
}
