@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

.app {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
}

/* total width */
*::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
